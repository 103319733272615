import React, { useEffect, useState } from 'react'
import vilLogoRed from '../../../../../../assets/images/vi-busi.png'
import vilLogoBlack from '../../../../../../assets/images/logo-bg-black.jpg'
import { Spinner, Center, useTheme } from '@chakra-ui/react'
import { useAuthContext } from '../../../../../../context/auth.context'
import {
	useLoadRomsFormFieldListData,
	useLoadAllFormData,
} from '../../../../../../hooks/form.hooks'
import { loadImageBlob } from '../../../../../../utils/aws.util'
import { encode } from '../../../caf-crf-view/cafCrf.util'
import crfStyle from './hsdwanCrfForm.module.css'
import SdwanCrfTermsConditionsNew from '../../sdwanCrfTermsConditions'

const HsdwanCrfForm = ({
	formData,
	queueData,
	romsActivity,
	submittedForms,
}) => {
	const chakraTheme = useTheme()
	let componentRef = React.useRef()
	const [crfData, setcrfData] = useState(false)
	const [hsdWancrfDataupdated, setUpdatedcrfData] = useState(null)
	const [imageData, setImageData] = useState(null)
	const [imageType, setImageType] = useState(null)
	const [custImageData, setCustImageData] = useState(null)
	const [custImageType, setCustImageType] = useState(null)
	const [acctMngSignImageData, setacctMngSignImageData] = useState(null)
	const [acctMngSignImageType, setacctMngSignImageType] = useState(null)
	const [tableData, setTableData] = useState([])
	const {
		state: { authData },
	} = useAuthContext()
	let hSdwanCrfContentView = ''

	const { mutate } = useLoadRomsFormFieldListData()
	const { mutate: loadAllData } = useLoadAllFormData()
	const fetchFieldListData = () => {
		mutate(
			{
				fieldList: {
					organization_id: authData.organization_id,
					account_id: authData.account_id,
					workforce_id: authData.workforce_id,
					form_id: formData.form_id,
				},
				fieldValues: {
					form_id: formData.form_id,
					form_transaction_id: formData.form_transaction_id
						? formData.form_transaction_id
						: formData.data_form_transaction_id,
					start_from: 0,
					limit_value: 50,
				},
			},
			{
				onSuccess: async data => {
					let finalFields = data
					setcrfData(false)
					setUpdatedcrfData(finalFields)
				},
				onError: async err => {
					console.log('error occurred while loading fields', err)
				},
			}
		)
	}
	const fetchTableData = () => {
		loadAllData(
			{
				workflow_activity_id: queueData?.activity_id,
				form_id: romsActivity?.multi_submission_form_id,
				page_start: 0,
				page_limit: 50,
			},
			{
				onSuccess: async data => {
					if (data.length > 0) {
						let allInlineActivitiesArray = []
						for (const item of data) {
							if (!!item.activity_inline_data)
								allInlineActivitiesArray.push(item.activity_inline_data)
						}
						setTableData(allInlineActivitiesArray)
					}
				},
				onError: async err => {
					console.log('error occurred while loading fields', err)
				},
			}
		)
	}
	useEffect(() => {
		setcrfData(true)
		fetchFieldListData()
		fetchTableData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {
		let data = hsdWancrfDataupdated
		if (!!data) {
			if (!!data[348661]?.value) {
				// customer company seal
				let custCompanySeal = data[348661]?.value

				loadImageBlob(custCompanySeal, (data, type) => {
					setImageData(data)
					setImageType(type)
				})
			}
			if (!!data[348655]?.value) {
				// customer  sign
				let custCompanySeal = data[348655]?.value
				loadImageBlob(custCompanySeal, (data, type) => {
					setCustImageData(data)
					setCustImageType(type)
				})
			}
			if (!!data[348660]?.value) {
				// manager sign url
				let acctManagerSignUrl = data[348660]?.value
				loadImageBlob(acctManagerSignUrl, (data, type) => {
					setacctMngSignImageData(data)
					setacctMngSignImageType(type)
				})
			}
		}
	}, [hsdWancrfDataupdated])
	if (!!hsdWancrfDataupdated) {
		let customerSignSplit =
			!!hsdWancrfDataupdated[348655]?.value &&
			hsdWancrfDataupdated[348655]?.value?.length > 0
				? hsdWancrfDataupdated[348655]?.value.split(' ')
				: ''
		let firstCaps
		let secondBold
		let Signcss
		if (customerSignSplit?.length > 0) {
			firstCaps = customerSignSplit['0']?.slice(0, 1)?.toUpperCase()
			secondBold = customerSignSplit['1']
			Signcss = 'signate_img input_sign'
		} else {
			firstCaps = customerSignSplit
			secondBold = ''
			Signcss = 'signate_img input_sign under_line'
		}

		hSdwanCrfContentView = (
			<div>
				<div
					style={{ textAlign: 'left', marginTop: '5px' }}
					className={crfStyle.tab_content2}
					ref={el => (componentRef = el)}
				>
					<page size='A4'>
						<div className={crfStyle.section2}>
							{' '}
							<div className={crfStyle.font8}>
								<p className={crfStyle.over_hid}>
									<img src={vilLogoRed} />
								</p>
								<p className={crfStyle.heading}>
									Hybrid SDWAN Change Request Form
								</p>
								<div className={crfStyle.pad_20t}>
									<div className={crfStyle.over_hid}>
										<div className={crfStyle.pd_t5}>
											<div className={crfStyle.caf}>
												{' '}
												<strong>CAF ID</strong>
												<input
													className={crfStyle.form_col}
													type='text'
													name=''
													value={hsdWancrfDataupdated[348427]?.value}
												/>
											</div>
										</div>
									</div>
									<div className={`${crfStyle.over_hid} ${crfStyle.mar_t - 5}`}>
										<div className={crfStyle.pd_t5}>
											{' '}
											<span className={crfStyle.pad_l10}></span>
											<div className={crfStyle.caf_inner}>
												<strong>Account Code</strong>
												<input
													className={`${crfStyle.form_col} ${crfStyle.no_top}`}
													type='text'
													name=''
													value={hsdWancrfDataupdated[348428]?.value}
												/>
											</div>
										</div>
									</div>

									<div className={`${crfStyle.over_hid} ${crfStyle.mar_t - 5}`}>
										<div className={crfStyle.pd_t5}>
											<strong>Company(Customer) Name</strong>{' '}
											<span className={crfStyle.pad_l10}>
												<input
													className={crfStyle.form_col_input}
													type='text'
													name=''
													value={hsdWancrfDataupdated[348429]?.value}
												/>
											</span>
										</div>
									</div>
									<p
										className={`${crfStyle.sign} ${crfStyle.font12} ${crfStyle.underline}`}
									>
										<strong>Authorised Signatory Details</strong>
									</p>
								</div>
								<div className={`${crfStyle.over_hid} ${crfStyle.auth}`}>
									<div className={`${crfStyle.fleft} ${crfStyle.width45}`}>
										Name
										<input
											className={`${crfStyle.form_col} ${crfStyle.width85}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348431]?.value}
										/>
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width25}`}>
										Contact No:
										<input
											className={`${crfStyle.form_col} ${crfStyle.width59}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348432]?.value}
										/>
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width30}`}>
										Email:
										<input
											className={`${crfStyle.form_col} ${crfStyle.width80}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348433]?.value}
										/>
									</div>
								</div>
								<div className={`${crfStyle.over_hid} ${crfStyle.pad_t10}`}>
									<div className={`${crfStyle.fleft} ${crfStyle.width20}`}>
										{' '}
										Feasibility ID (FR ID){' '}
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width45}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.width50}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348434]?.value}
										/>
									</div>
								</div>
								<div className={`${crfStyle.over_hid} ${crfStyle.pad_5t}`}>
									<div className={`${crfStyle.fleft} ${crfStyle.width20}`}>
										{' '}
										Please mention circuit id.
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width45}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.width50}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348435]?.value}
										/>
									</div>
								</div>
								<div className={`${crfStyle.over_hid} ${crfStyle.pad_5t}`}>
									<div className={`${crfStyle.fleft} ${crfStyle.width20}`}>
										Please mention VOX ID
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width45}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.width50}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348436]?.value}
										/>
									</div>
								</div>
								{/* <p
									className={`${crfStyle.margin0} ${crfStyle.font15} ${crfStyle.typof}}`}
								> */}
								<p
									className={`${crfStyle.textcenter} ${crfStyle.font15} ${crfStyle.margin0} ${crfStyle.pad_t10} ${crfStyle.pad_b5} ${crfStyle.hed5}`}
								>
									TYPE OF CHANGE REQUEST ORDER
								</p>

								<div className={`${crfStyle.over_hid} ${crfStyle.pad_t15}`}>
									<div className={`${crfStyle.fleft} ${crfStyle.width45}`}>
										<div className={crfStyle.over_hid}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.width80} ${crfStyle.pad_t2}}`}
											>
												{' '}
												Company Name Change{' '}
											</div>
											<div className={crfStyle.fleft}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.check15}`}
													type='checkbox'
													name=''
													value=''
													checked={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('Company Name Change') >= 0
													}
													disabled={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('Company Name Change') < 0
													}
												/>
											</div>
										</div>
										<div className={`${crfStyle.over_hid} ${crfStyle.pad_t2}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.width80} ${crfStyle.pad_t2}}`}
											>
												{' '}
												Customer Contact Change
											</div>
											<div className={crfStyle.fleft}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.check15}`}
													type='checkbox'
													name=''
													value=''
													checked={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('Customer Contact Change') >= 0
													}
													disabled={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('Customer Contact Change') < 0
													}
												/>
											</div>
										</div>
										<div className={`${crfStyle.over_hid} ${crfStyle.pad_t2}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.width80} ${crfStyle.pad_t2}}`}
											>
												{' '}
												Billing Address Change
											</div>
											<div className={crfStyle.fleft}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.check15}`}
													type='checkbox'
													name=''
													value=''
													checked={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('Billing Address Change') >= 0
													}
													disabled={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('Billing Address Change') < 0
													}
												/>
											</div>
										</div>
										<div className={`${crfStyle.over_hid} ${crfStyle.pad_t2}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.width80} ${crfStyle.pad_t2}}`}
											>
												{' '}
												Change In CPE Add/Remove
											</div>
											<div className={crfStyle.fleft}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.check15}`}
													type='checkbox'
													name=''
													value=''
													checked={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('Change In CPE Add/Remove') >= 0
													}
													disabled={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('Change In CPE Add/Remove') < 0
													}
												/>
											</div>
										</div>
										<div className={`${crfStyle.over_hid} ${crfStyle.pad_t2}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.width80} ${crfStyle.pad_t2}}`}
											>
												{' '}
												SDWAN Service Changes
											</div>
											<div className={crfStyle.fleft}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.check15}`}
													type='checkbox'
													name=''
													value=''
													checked={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('SDWAN Service Changes') >= 0
													}
													disabled={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('SDWAN Service Changes') < 0
													}
												/>
											</div>
										</div>
										<div className={`${crfStyle.over_hid} ${crfStyle.pad_t2}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.width80} ${crfStyle.pad_t2}}`}
											>
												SDWAN Configuration Changes
											</div>
											<div className={crfStyle.fleft}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.check15}`}
													type='checkbox'
													name=''
													value=''
													checked={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('SDWAN Configuration Changes') >= 0
													}
													disabled={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('SDWAN Configuration Changes') < 0
													}
												/>
											</div>
										</div>
										{/* <div className={`${crfStyle.over_hid} ${crfStyle.pad_t2}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.width80} ${crfStyle.pad_t2}}`}
											>
												SDWAN Configuration Changes
											</div>
											<div className={crfStyle.fleft}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.check15}`}
													type='checkbox'
													name=''
													value=''
                          checked={
														hsdWancrfDataupdated[348437]?.value ===
														'SDWAN Configuration Changes'
													}
													disabled={
														hsdWancrfDataupdated[348437]?.value !==
														'SSDWAN Configuration Changes'
													}
												/>
											</div>
										</div> */}
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width45}`}>
										<div className={crfStyle.over_hid}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.width80} ${crfStyle.pad_t2}}`}
											>
												{' '}
												Site Shifting
											</div>
											<div className={crfStyle.fleft}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.check15}`}
													type='checkbox'
													name=''
													value=''
													checked={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('Site Shifting') >= 0
													}
													disabled={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('Site Shifting') < 0
													}
												/>
											</div>
										</div>
										<div className={`${crfStyle.over_hid} ${crfStyle.pad_t2}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.width80} ${crfStyle.pad_t2}}`}
											>
												{' '}
												Billing Periodicity Change
											</div>
											<div className={crfStyle.fleft}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.check15}`}
													type='checkbox'
													name=''
													value=''
													checked={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('Billing Periodicity Change') >= 0
													}
													disabled={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('Billing Periodicity Change') < 0
													}
												/>
											</div>
										</div>
										<div className={`${crfStyle.over_hid} ${crfStyle.pad_t2}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.width80} ${crfStyle.pad_t2}}`}
											>
												{' '}
												Change In SLA Type
											</div>
											<div className={crfStyle.fleft}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.check15}`}
													type='checkbox'
													name=''
													value=''
													checked={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('Change In SLA Type') >= 0
													}
													disabled={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('Change In SLA Type') < 0
													}
												/>
											</div>
										</div>
										<div className={`${crfStyle.over_hid} ${crfStyle.pad_t2}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.width80} ${crfStyle.pad_t2}}`}
											>
												{' '}
												Change In Self Care Service Variant
											</div>
											<div className={crfStyle.fleft}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.check15}`}
													type='checkbox'
													name=''
													value=''
													checked={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('Change In Self Care Service Variant') >=
														0
													}
													disabled={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('Change In Self Care Service Variant') <
														0
													}
												/>
											</div>
										</div>
										<div className={`${crfStyle.over_hid} ${crfStyle.pad_t2}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.width80} ${crfStyle.pad_t2}}`}
											>
												{' '}
												Revision In Charges
											</div>
											<div className={crfStyle.fleft}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.check15}`}
													type='checkbox'
													name=''
													value=''
													checked={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('Revision In Charges') >= 0
													}
													disabled={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('Revision In Charges') < 0
													}
												/>
											</div>
										</div>
										<div className={`${crfStyle.over_hid} ${crfStyle.pad_t2}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.width80} ${crfStyle.pad_t2}}`}
											>
												{' '}
												SDWAN Hardware And Software Changes
											</div>
											<div className={crfStyle.fleft}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.check15}`}
													type='checkbox'
													name=''
													value=''
													checked={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('SDWAN Hardware And Software Changes') >=
														0
													}
													disabled={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('SDWAN Hardware And Software Changes') <
														0
													}
												/>
											</div>
										</div>
										<div className={`${crfStyle.over_hid} ${crfStyle.pad_t2}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.width80} ${crfStyle.pad_t2}}`}
											>
												{' '}
												SDWAN Underlay Change{' '}
											</div>
											<div className={crfStyle.fleft}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.check15}`}
													type='checkbox'
													name=''
													value=''
													checked={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('SDWAN Underlay Change') >= 0
													}
													disabled={
														hsdWancrfDataupdated[348437].value
															.split('|')
															.indexOf('SDWAN Underlay Change') < 0
													}
												/>
											</div>
										</div>
									</div>
								</div>
								<p
									className={`${crfStyle.textcenter} ${crfStyle.font15} ${crfStyle.margin0} ${crfStyle.pad_t10} ${crfStyle.pad_b5} ${crfStyle.hed5}`}
								>
									Company Name Change
								</p>
								<div
									className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pad_t6}}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width170px} ${crfStyle.pad_t3}}`}
									>
										Existing Name of Company
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width49}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348439]?.value}
										/>
									</div>
								</div>
								<div
									className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pad_t6}}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width170px} ${crfStyle.pad_t3}}`}
									>
										&nbsp;
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width49}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
											type='text'
											name=''
											value=''
										/>
									</div>
								</div>
								<div
									className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pad_t6}}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width170px} ${crfStyle.pad_t3}}`}
									>
										New Name of Company
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width49}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348440]?.value}
										/>
									</div>
								</div>
								<div
									className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pad_t6}}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width170px} ${crfStyle.pad_t3}}`}
									>
										&nbsp;
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width49}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
											type='text'
											name=''
											value=''
										/>
									</div>
								</div>
								<p
									className={`${crfStyle.textcenter} ${crfStyle.font15} ${crfStyle.margin0} ${crfStyle.pad_t10} ${crfStyle.pad_b5} ${crfStyle.hed5}`}
								>
									COMPANY CONTACT DETAILS CHANGE
								</p>
								<div
									className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pad_t6}}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width170px} ${crfStyle.pad_t3}}`}
									>
										Existing Telephone Number
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width49}`}>
										{' '}
										<input
											className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348442]?.value}
										/>
									</div>
								</div>
								<div
									className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pad_t6}}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width170px} ${crfStyle.pad_t3}}`}
									>
										New Telephone Number
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width49}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348443]?.value}
										/>
									</div>
								</div>
								<div
									className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pad_t6}}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width170px} ${crfStyle.pad_t3}}`}
									>
										Existing Email Address
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width49}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348444]?.value}
										/>
									</div>
								</div>
								<div
									className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pad_t6}}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width170px} ${crfStyle.pad_t3}}`}
									>
										New Email Address
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width49}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348445]?.value}
										/>
									</div>
								</div>
								<div className={crfStyle.signateNew}>
									{firstCaps !== '' ? (
										<div style={{ padding: '0px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData !== null && imageData !== '' ? (
										<div style={{ padding: '0px' }} className='signate_img'>
											<img
												src={
													imageData !== null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					</page>

					<page size='A4'>
						<div className={crfStyle.section2}>
							<div className={crfStyle.font8}>
								<p
									className={`${crfStyle.textcenter} ${crfStyle.font15} ${crfStyle.margin0} ${crfStyle.pad_t10} ${crfStyle.pad_b5} ${crfStyle.hed5}`}
								>
									BILLING ADDRESS CHANGE
								</p>
								<p className={`${crfStyle.font12} ${crfStyle.margin0} `}>
									{' '}
									<strong>New Billiing Address</strong>{' '}
								</p>
								<div
									className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pad_t6} ${crfStyle.mar_t5}}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.wid120p} ${crfStyle.pad_t3}}`}
									>
										Contact Preson Name
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width81}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348448]?.value}
										/>
									</div>
								</div>
								<div className={`${crfStyle.over_hid} ${crfStyle.pad_t15}`}>
									<div className={`${crfStyle.fleft} ${crfStyle.wid50}`}>
										<div className={`${crfStyle.check} ${crfStyle.over_hid}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.wid120p} ${crfStyle.pad_t3}}`}
											>
												Designation
											</div>
											<div className={`${crfStyle.fleft} ${crfStyle.width63}`}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
													type='text'
													name=''
													value={hsdWancrfDataupdated[348449]?.value}
												/>
											</div>
										</div>
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.wid50}`}>
										<div className={`${crfStyle.check} ${crfStyle.over_hid}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.wid120p} ${crfStyle.pad_t3} ${crfStyle.textcenter}`}
											>
												<span class=''>Dept. Name</span>
											</div>
											<div className={`${crfStyle.fleft} ${crfStyle.width63}`}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
													type='text'
													name=''
													value={hsdWancrfDataupdated[348450]?.value}
												/>
											</div>
										</div>
									</div>
								</div>
								<div
									className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pad_t6}}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.wid120p} ${crfStyle.pad_t3}}`}
									>
										Address
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width81}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348451]?.value}
										/>
									</div>
								</div>
								<div
									className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pad_t6}}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.wid120p} ${crfStyle.pad_t3}}`}
									>
										{' '}
										&nbsp;{' '}
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width81}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
											type='text'
											name=''
											value=''
										/>
									</div>
								</div>
								<div className={`${crfStyle.over_hid} ${crfStyle.pad_t15}`}>
									<div className={`${crfStyle.fleft} ${crfStyle.wid50}`}>
										<div className={`${crfStyle.check} ${crfStyle.over_hid}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.wid120p} ${crfStyle.pad_t3}}`}
											>
												City / Village / Post Office
											</div>
											<div className={`${crfStyle.fleft} ${crfStyle.width63}`}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
													type='text'
													name=''
													value={hsdWancrfDataupdated[348452]?.value}
												/>
											</div>
										</div>
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.wid25}`}>
										<div className={`${crfStyle.check} ${crfStyle.over_hid}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.pad_t3} ${crfStyle.textcenter} ${crfStyle.pad_l10} ${crfStyle.pad_r5}`}
											>
												<span class=''>Pin</span>
											</div>
											<div className={`${crfStyle.fleft} ${crfStyle.width63}`}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
													type='text'
													name=''
													value={hsdWancrfDataupdated[348453]?.value}
												/>
											</div>
										</div>
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.wid25}`}>
										<div className={`${crfStyle.check} ${crfStyle.over_hid}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.pad_t3} ${crfStyle.textcenter} ${crfStyle.pad_l10} ${crfStyle.pad_r5}`}
											>
												<span class=''>Telephone</span>
											</div>
											<div className={`${crfStyle.fleft} ${crfStyle.width63}`}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.width90}`}
													type='text'
													name=''
													value={hsdWancrfDataupdated[348454]?.value}
												/>
											</div>
										</div>
									</div>

									<div
										className={`${crfStyle.auth} ${crfStyle.margin0} ${crfStyle.over_hid}}`}
									>
										<div
											className={`${crfStyle.fleft} ${crfStyle.wid120p} ${crfStyle.pad_t3}}`}
										>
											Landmark
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width81}`}>
											<input
												className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
												type='text'
												name=''
												value={hsdWancrfDataupdated[348455]?.value}
											/>
										</div>
									</div>
								</div>
								{/* <div
									className={`${crfStyle.auth} ${crfStyle.margin0} ${crfStyle.over_hid}}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.wid120p} ${crfStyle.pad_t3} ${crfStyle.text_r}`}
									>
										Landmark &nbsp; &nbsp;{' '}
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width63}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348455]?.value}
										/>
									</div>
								</div> */}
								<p
									className={`${crfStyle.textcenter} ${crfStyle.font15} ${crfStyle.margin0} ${crfStyle.pad_t10} ${crfStyle.pad_b5} ${crfStyle.hed5}`}
								>
									SITE SHIFTING INSTALLATION ADDRESS CHANGE
								</p>

								<p className={crfStyle.font12}>
									{' '}
									<strong>Contact Preson Name</strong>{' '}
								</p>
								<div
									className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pad_t6} ${crfStyle.mar_t5}}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.wid120p} ${crfStyle.pad_t3}}`}
									>
										Contact Person name
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width81}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348458]?.value}
										/>
									</div>
								</div>
								<div className={`${crfStyle.over_hid} ${crfStyle.pad_t15}`}>
									<div className={`${crfStyle.fleft} ${crfStyle.wid50}`}>
										<div className={`${crfStyle.check} ${crfStyle.over_hid}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.wid120p} ${crfStyle.pad_t3}}`}
											>
												Designation
											</div>
											<div className={`${crfStyle.fleft} ${crfStyle.width63}`}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
													type='text'
													name=''
													value={hsdWancrfDataupdated[348459]?.value}
												/>
											</div>
										</div>
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.wid50}`}>
										<div className={`${crfStyle.check} ${crfStyle.over_hid}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.wid120p} ${crfStyle.pad_t3} ${crfStyle.textcenter}`}
											>
												<span class=''>Dept. Name</span>
											</div>
											<div className={`${crfStyle.fleft} ${crfStyle.width63}`}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
													type='text'
													name=''
													value={hsdWancrfDataupdated[348460]?.value}
												/>
											</div>
										</div>
									</div>
								</div>
								<div
									className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pad_t6}}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.wid120p} ${crfStyle.pad_t3}}`}
									>
										Address
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width81}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348461]?.value}
										/>
									</div>
								</div>
								<div
									className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pad_t6}}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.wid120p} ${crfStyle.pad_t3}}`}
									>
										{' '}
										&nbsp;{' '}
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width81}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
											type='text'
											name=''
											value=''
										/>
									</div>
								</div>
								<div className={`${crfStyle.over_hid} ${crfStyle.pad_t15}`}>
									<div className={`${crfStyle.fleft} ${crfStyle.wid50}`}>
										<div className={`${crfStyle.check} ${crfStyle.over_hid}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.wid120p} ${crfStyle.pad_t3}}`}
											>
												City / Village / Post Office
											</div>
											<div className={`${crfStyle.fleft} ${crfStyle.width63}`}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
													type='text'
													name=''
													value={hsdWancrfDataupdated[348462]?.value}
												/>
											</div>
										</div>
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.wid25}`}>
										<div className={`${crfStyle.check} ${crfStyle.over_hid}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.pad_t3} ${crfStyle.textcenter} ${crfStyle.pad_l10} ${crfStyle.pad_r5}`}
											>
												<span class=''>Pin</span>
											</div>
											<div className={`${crfStyle.fleft} ${crfStyle.width63}`}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
													type='text'
													name=''
													value={hsdWancrfDataupdated[348463]?.value}
												/>
											</div>
										</div>
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.wid25}`}>
										<div className={`${crfStyle.check} ${crfStyle.over_hid}`}>
											<div
												className={`${crfStyle.fleft} ${crfStyle.pad_t3} ${crfStyle.textcenter} ${crfStyle.pad_l10} ${crfStyle.pad_r5}`}
											>
												<span class=''>Telephone</span>
											</div>
											<div className={`${crfStyle.fleft} ${crfStyle.width63}`}>
												<input
													className={`${crfStyle.form_col} ${crfStyle.width90}`}
													type='text'
													name=''
													value={hsdWancrfDataupdated[348464]?.value}
												/>
											</div>
										</div>
									</div>
									<div
										className={`${crfStyle.auth} ${crfStyle.margin0} ${crfStyle.over_hid}}`}
									>
										<div
											className={`${crfStyle.fleft} ${crfStyle.wid120p} ${crfStyle.pad_t3}}`}
										>
											Landmark
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width81}`}>
											<input
												className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
												type='text'
												name=''
												value={hsdWancrfDataupdated[348465]?.value}
											/>
										</div>
									</div>
								</div>
								{/* <div
									className={`${crfStyle.auth} ${crfStyle.margin0} ${crfStyle.over_hid}}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.wid120p} ${crfStyle.pad_t3}}`}
									>
										Landmark &nbsp; &nbsp;{' '}
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width63}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348465]?.value}
										/>
									</div>
								</div> */}

								<p
									className={`${crfStyle.textcenter} ${crfStyle.font15} ${crfStyle.margin0} ${crfStyle.pad_t10} ${crfStyle.pad_b5} ${crfStyle.hed5}`}
								>
									CHANGE IN SDWAN CPE
								</p>
								<p className={crfStyle.font15}>Addition of CPE</p>
								<div className={crfStyle.domestictable}>
									<div className={crfStyle.center_70per}>
										{' '}
										<table
											width='100%'
											border='0'
											cellspacing='0'
											cellpadding='0'
										>
											<tbody>
												<tr>
													<td>Sr. No.</td>
													<td>Type (Standard/Other)</td>
													<td>Make</td>
													<td>Model</td>
													<td>Version</td>
												</tr>
												<tr>
													<td>
														{' '}
														{hsdWancrfDataupdated[348468]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348469]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348470]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348471]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348472]?.value || '\u00A0'}
													</td>
												</tr>
												<tr>
													<td>
														{' '}
														{hsdWancrfDataupdated[348473]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348474]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348475]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348476]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348477]?.value || '\u00A0'}
													</td>
												</tr>
												<tr>
													<td>
														{' '}
														{hsdWancrfDataupdated[348478]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348479]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348480]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348481]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348482]?.value || '\u00A0'}
													</td>
												</tr>
											</tbody>
										</table>{' '}
									</div>
								</div>
								{/* <p
									className={`${crfStyle.textcenter} ${crfStyle.font15} ${crfStyle.margin0} ${crfStyle.pad_t10} ${crfStyle.pad_b5} ${crfStyle.hed5}`}
								>
									Deletion of CPE
								</p> */}
								<p className={crfStyle.font15}>Deletion of CPE</p>
								<div className={crfStyle.domestictable}>
									<div className={crfStyle.center_70per}>
										<table
											width='100%'
											border='0'
											cellspacing='0'
											cellpadding='0'
										>
											<tbody>
												<tr>
													<td>Sr. No.</td>
													<td>Type (Standard/Other)</td>
													<td>Make</td>
													<td>Model</td>
													<td>Version</td>
												</tr>
												<tr>
													<td>
														{' '}
														{hsdWancrfDataupdated[348484]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348485]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348486]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348487]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348488]?.value || '\u00A0'}
													</td>
												</tr>
												<tr>
													<td>
														{' '}
														{hsdWancrfDataupdated[348489]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348490]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348491]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348492]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348493]?.value || '\u00A0'}
													</td>
												</tr>
												<tr>
													<td>
														{' '}
														{hsdWancrfDataupdated[348494]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348495]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348496]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348497]?.value || '\u00A0'}
													</td>
													<td>
														{' '}
														{hsdWancrfDataupdated[348498]?.value || '\u00A0'}
													</td>
												</tr>
											</tbody>
										</table>{' '}
									</div>
								</div>

								<p
									className={`${crfStyle.textcenter} ${crfStyle.font15} ${crfStyle.margin0} ${crfStyle.pad_t10} ${crfStyle.pad_b5} ${crfStyle.hed5}`}
								>
									BILLING PERIODICITY CHANGE
								</p>
								<div className={crfStyle.pad_t5}>
									<div
										className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pd_t5}}`}
									>
										<div
											className={`${crfStyle.fleft} ${crfStyle.pad_t3} ${crfStyle.width20}}`}
										>
											{' '}
											Existing Billing Periodicity &nbsp; &nbsp;{' '}
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width16}`}>
											<input
												className={crfStyle.form_col}
												type='checkbox'
												name=''
												checked={
													hsdWancrfDataupdated[348500]?.value === 'Monthly'
												}
												disabled={
													hsdWancrfDataupdated[348500]?.value !== 'Monthly'
												}
											/>
											Monthly
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width16}`}>
											<input
												className={crfStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked={
													hsdWancrfDataupdated[348500]?.value === 'Bi-Monthly'
												}
												disabled={
													hsdWancrfDataupdated[348500]?.value !== 'Bi-Monthly'
												}
											/>
											Bi-Monthly
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width16}`}>
											<input
												className={crfStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked={
													hsdWancrfDataupdated[348500]?.value === 'Quarterly'
												}
												disabled={
													hsdWancrfDataupdated[348500]?.value !== 'Quarterly'
												}
											/>
											Quarterly
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width16}`}>
											<input
												className={crfStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked={
													hsdWancrfDataupdated[348500]?.value === 'Half Yearly'
												}
												disabled={
													hsdWancrfDataupdated[348500]?.value !== 'Half Yearly'
												}
											/>
											Half Yearly
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width16}`}>
											<input
												className={crfStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked={
													hsdWancrfDataupdated[348500]?.value === 'Annualy'
												}
												disabled={
													hsdWancrfDataupdated[348500]?.value !== 'Annualy'
												}
											/>
											Annualy
										</div>
									</div>
								</div>
								<div className={crfStyle.pad_t5}>
									<div
										className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pd_t5}}`}
									>
										<div
											className={`${crfStyle.fleft} ${crfStyle.pad_t3} ${crfStyle.width20}}`}
										>
											{' '}
											Revised Billing Periodicity &nbsp; &nbsp;
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width16}`}>
											<input
												className={crfStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked={
													hsdWancrfDataupdated[348501]?.value === 'Monthly'
												}
												disabled={
													hsdWancrfDataupdated[348501]?.value !== 'Monthly'
												}
											/>
											Monthly
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width16}`}>
											<input
												className={crfStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked={
													hsdWancrfDataupdated[348501]?.value === 'Bi-Monthly'
												}
												disabled={
													hsdWancrfDataupdated[348501]?.value !== 'Bi-Monthly'
												}
											/>
											Bi-Monthly
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width16}`}>
											<input
												className={crfStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked={
													hsdWancrfDataupdated[348501]?.value === 'Quarterly'
												}
												disabled={
													hsdWancrfDataupdated[348501]?.value !== 'Quarterly'
												}
											/>
											Quarterly
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width16}`}>
											<input
												className={crfStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked={
													hsdWancrfDataupdated[348501]?.value === 'Half Yearly'
												}
												disabled={
													hsdWancrfDataupdated[348501]?.value !== 'Half Yearly'
												}
											/>
											Half Yearly
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width16}`}>
											<input
												className={crfStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked={
													hsdWancrfDataupdated[348501]?.value === 'Annualy'
												}
												disabled={
													hsdWancrfDataupdated[348501]?.value !== 'Annualy'
												}
											/>
											Annualy
										</div>
									</div>
								</div>
								<div className={crfStyle.signateNew}>
									{firstCaps !== '' ? (
										<div style={{ padding: '0px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData !== null && imageData !== '' ? (
										<div style={{ padding: '0px' }} className='signate_img'>
											<img
												src={
													imageData !== null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					</page>

					<page size='A4'>
						<div className={crfStyle.section2}>
							<div className={crfStyle.font8}>
								<p
									className={`${crfStyle.textcenter} ${crfStyle.font15} ${crfStyle.margin0} ${crfStyle.pad_t10} ${crfStyle.pad_b5} ${crfStyle.hed5}`}
								>
									Revision in Charges
								</p>

								<div className={crfStyle.char}>
									<div
										className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pad_t5}}`}
									>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width170px} ${crfStyle.pad_t3}}`}
										>
											{' '}
											Specify Currency{' '}
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width7}`}>
											<input
												className={crfStyle.form_col}
												type='checkbox'
												name=''
												checked={hsdWancrfDataupdated[348503]?.value === 'INR'}
												disabled={hsdWancrfDataupdated[348503]?.value !== 'INR'}
											/>
											INR{' '}
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width7}`}>
											<input
												className={crfStyle.form_col}
												type='checkbox'
												name=''
												checked={hsdWancrfDataupdated[348503]?.value === 'US$'}
												disabled={hsdWancrfDataupdated[348503]?.value !== 'US$'}
											/>
											US${' '}
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width15} ${crfStyle.textcenter}}`}
										>
											{' '}
											One Time(A){' '}
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width15} ${crfStyle.textcenter}}`}
										>
											{' '}
											Recurring (B){' '}
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width15} ${crfStyle.textcenter}}`}
										>
											{' '}
											Security Deposit ©{' '}
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width15} ${crfStyle.textcenter}}`}
										>
											{' '}
											Grand Total (A+B+C){' '}
										</div>
									</div>
									<div className={`${crfStyle.check} ${crfStyle.over_hid}`}>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width170px} ${crfStyle.pad_t3}}`}
										>
											{' '}
											SDWAN Managed Service Charges
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width7}`}>
											&nbsp;{' '}
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width7}`}>
											{' '}
											&nbsp;
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width15} ${crfStyle.textcenter}}`}
										>
											<input
												className={`${crfStyle.form_col} ${crfStyle.width75}`}
												type='text'
												name=''
												value={hsdWancrfDataupdated[348504]?.value}
											/>
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width15} ${crfStyle.textcenter}}`}
										>
											<input
												className={`${crfStyle.form_col} ${crfStyle.width75}`}
												type='text'
												name=''
												value={hsdWancrfDataupdated[348505]?.value}
											/>
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width15} ${crfStyle.textcenter}}`}
										>
											<input
												className={`${crfStyle.form_col} ${crfStyle.width75}`}
												type='text'
												name=''
												value={hsdWancrfDataupdated[348506]?.value}
											/>
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width15} ${crfStyle.textcenter}}`}
										>
											<input
												className={`${crfStyle.form_col} ${crfStyle.width75}`}
												type='text'
												name=''
												value={hsdWancrfDataupdated[348507]?.value}
											/>
										</div>
									</div>

									<div className={`${crfStyle.check} ${crfStyle.over_hid}`}>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width170px} ${crfStyle.pad_t3}}`}
										>
											{' '}
											SDWAN Controller Charges
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width7}`}>
											&nbsp;{' '}
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width7}`}>
											{' '}
											&nbsp;
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width15} ${crfStyle.textcenter}}`}
										>
											<input
												className={`${crfStyle.form_col} ${crfStyle.width75}`}
												type='text'
												name=''
												value={hsdWancrfDataupdated[348508]?.value}
											/>
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width15} ${crfStyle.textcenter}}`}
										>
											<input
												className={`${crfStyle.form_col} ${crfStyle.width75}`}
												type='text'
												name=''
												value={hsdWancrfDataupdated[348509]?.value}
											/>
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width15} ${crfStyle.textcenter}}`}
										>
											<input
												className={`${crfStyle.form_col} ${crfStyle.width75}`}
												type='text'
												name=''
												value={hsdWancrfDataupdated[348510]?.value}
											/>
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width15} ${crfStyle.textcenter}}`}
										>
											<input
												className={`${crfStyle.form_col} ${crfStyle.width75}`}
												type='text'
												name=''
												value={hsdWancrfDataupdated[348511]?.value}
											/>
										</div>
									</div>

									<div className={`${crfStyle.check} ${crfStyle.over_hid}`}>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width170px} ${crfStyle.pad_t3}}`}
										>
											{' '}
											SDWAN Hardware Charges
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width7}`}>
											&nbsp;{' '}
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width7}`}>
											{' '}
											&nbsp;
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width15} ${crfStyle.textcenter}}`}
										>
											<input
												className={`${crfStyle.form_col} ${crfStyle.width75}`}
												type='text'
												name=''
												value={hsdWancrfDataupdated[348512]?.value}
											/>
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width15} ${crfStyle.textcenter}}`}
										>
											<input
												className={`${crfStyle.form_col} ${crfStyle.width75}`}
												type='text'
												name=''
												value={hsdWancrfDataupdated[348513]?.value}
											/>
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width15} ${crfStyle.textcenter}}`}
										>
											<input
												className={`${crfStyle.form_col} ${crfStyle.width75}`}
												type='text'
												name=''
												value={hsdWancrfDataupdated[348514]?.value}
											/>
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width15} ${crfStyle.textcenter}}`}
										>
											<input
												className={`${crfStyle.form_col} ${crfStyle.width75}`}
												type='text'
												name=''
												value={hsdWancrfDataupdated[348515]?.value}
											/>
										</div>
									</div>

									<div className={`${crfStyle.check} ${crfStyle.over_hid}`}>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width170px} ${crfStyle.pad_t3}}`}
										>
											{' '}
											<strong>Total Order Value</strong>
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width7}`}>
											&nbsp;{' '}
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width7}`}>
											{' '}
											&nbsp;
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width60} ${crfStyle.textcenter}}`}
										>
											<input
												className={`${crfStyle.form_col} ${crfStyle.width94}`}
												type='text'
												name=''
												value={hsdWancrfDataupdated[348516]?.value}
											/>
										</div>
									</div>
								</div>

								<p
									className={`${crfStyle.textcenter} ${crfStyle.font15} ${crfStyle.margin0} ${crfStyle.pad_t10} ${crfStyle.pad_b5} ${crfStyle.hed5}`}
								>
									SDWAN UNDERLAY CHANGE
								</p>
								<p className={crfStyle.font15}>EXISTING WAN DETAILS</p>
								<div className={crfStyle.domestictable}>
									<div class=''>
										{' '}
										<table
											width='100%'
											border='0'
											cellspacing='0'
											cellpadding='0'
										>
											<tbody>
												<tr>
													<td>Sr. No.</td>
													<td>
														Underlay
														<br />
														(WAN1, WAN2, WAN3,..)
													</td>
													<td>
														WAN Type <br /> (MPLS, ILL, BB, 4G,..)
													</td>
													<td>
														Provided by <br />
														(VIL/Customer)
													</td>
													<td>
														If VIL Provided <br />
														(New/Existing)
													</td>
													<td>
														If VIL Existing, <br />
														Circuit ID
													</td>
													<td>
														If VIL New, <br />
														OSM ID// Feasibility ID
													</td>
													<td>
														If Customer provided, <br />
														ISP Name
													</td>
													<td>
														If Customer provided, <br />
														Circuit ID
													</td>
												</tr>
												<tr>
													<td>
														{hsdWancrfDataupdated[348519]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348520]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348521]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348522]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348523]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348524]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348525]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348526]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348527]?.value || '\u00A0'}
													</td>
												</tr>
												<tr>
													<td>
														{hsdWancrfDataupdated[348528]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348529]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348530]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348531]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348532]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348533]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348534]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348535]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348536]?.value || '\u00A0'}
													</td>
												</tr>
												<tr>
													<td>
														{hsdWancrfDataupdated[348537]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348538]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348539]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348540]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348541]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348542]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348543]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348544]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348545]?.value || '\u00A0'}
													</td>
												</tr>
												<tr>
													<td>
														{hsdWancrfDataupdated[348546]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348547]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348548]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348549]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348550]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348551]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348552]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348553]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348554]?.value || '\u00A0'}
													</td>
												</tr>
												<tr>
													<td>
														{hsdWancrfDataupdated[348555]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348556]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348557]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348558]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348559]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348560]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348561]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348562]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348563]?.value || '\u00A0'}
													</td>
												</tr>
											</tbody>
										</table>{' '}
									</div>
								</div>

								{/* <p
									className={`${crfStyle.textcenter} ${crfStyle.font15} ${crfStyle.margin0} ${crfStyle.pad_t10} ${crfStyle.pad_b5} ${crfStyle.hed5}`}
								>
									MODIFIED WAN DETAIL
								</p> */}
								<p className={crfStyle.font15}>MODIFIED WAN DETAILS</p>
								<div className={crfStyle.domestictable}>
									<div class=''>
										{' '}
										<table
											width='100%'
											border='0'
											cellspacing='0'
											cellpadding='0'
										>
											<tbody>
												<tr>
													<td>Sr. No.</td>
													<td>
														Underlay
														<br />
														(WAN1, WAN2, WAN3,..)
													</td>
													<td>
														WAN Type <br /> (MPLS, ILL, BB, 4G,..)
													</td>
													<td>
														Provided by <br />
														(VIL/Customer)
													</td>
													<td>
														If VIL Provided <br />
														(New/Existing)
													</td>
													<td>
														If VIL Existing, <br />
														Circuit ID
													</td>
													<td>
														If VIL New, <br />
														OSM ID// Feasibility ID
													</td>
													<td>
														If Customer provided, <br />
														ISP Name
													</td>
													<td>
														If Customer provided, <br />
														Circuit ID
													</td>
												</tr>
												<tr>
													<td>
														{hsdWancrfDataupdated[348565]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348566]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348567]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348568]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348569]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348570]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348571]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348572]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348573]?.value || '\u00A0'}
													</td>
												</tr>
												<tr>
													<td>
														{hsdWancrfDataupdated[348574]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348575]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348576]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348577]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348578]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348579]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348580]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348581]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348582]?.value || '\u00A0'}
													</td>
												</tr>
												<tr>
													<td>
														{hsdWancrfDataupdated[348583]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348584]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348585]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348586]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348587]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348588]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348589]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348590]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348591]?.value || '\u00A0'}
													</td>
												</tr>
												<tr>
													<td>
														{hsdWancrfDataupdated[348592]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348593]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348594]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348595]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348596]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348597]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348598]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348599]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348600]?.value || '\u00A0'}
													</td>
												</tr>
												<tr>
													<td>
														{hsdWancrfDataupdated[348601]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348602]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348603]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348604]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348605]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348606]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348607]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348608]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348609]?.value || '\u00A0'}
													</td>
												</tr>
											</tbody>
										</table>{' '}
									</div>
								</div>

								<p
									className={`${crfStyle.textcenter} ${crfStyle.font15} ${crfStyle.margin0} ${crfStyle.pad_t10} ${crfStyle.pad_b5} ${crfStyle.hed5}`}
								>
									SDWAN CONFIGURATION CHANGE
								</p>

								<div
									className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pad_t6}}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.pad_r15} ${crfStyle.pad_t3}}`}
									>
										New Configuration Changes
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width30}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348611]?.value}
										/>
									</div>
								</div>
								<p
									className={`${crfStyle.textcenter} ${crfStyle.font15} ${crfStyle.margin0} ${crfStyle.pad_t10} ${crfStyle.pad_b5} ${crfStyle.hed5}`}
								>
									SDWAN HARDWARE AND SOFTWARE CHANGE
								</p>
								<div
									className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pad_t6}}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.pad_r15} ${crfStyle.pad_t3} ${crfStyle.w220px}`}
									>
										Existing aggregated Bandwidth (Mbps/Gbps)
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width30px}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348613]?.value}
										/>
									</div>
								</div>

								<div
									className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pad_t6}}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.pad_r15} ${crfStyle.pad_t3} ${crfStyle.w220px}`}
									>
										Modified aggregated Bandwidth (Mbps/Gbps)
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width30px}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348614]?.value}
										/>
									</div>
								</div>
								<div
									className={`${crfStyle.check} ${crfStyle.over_hid} ${crfStyle.pad_t6}}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.pad_r15} ${crfStyle.pad_t3} ${crfStyle.w220px}`}
									>
										New Configuration Changes
									</div>
									<div className={`${crfStyle.fleft} ${crfStyle.width30}`}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.widthfull}`}
											type='text'
											name=''
											value={hsdWancrfDataupdated[348615]?.value}
										/>
									</div>
								</div>

								<p
									className={`${crfStyle.textcenter} ${crfStyle.font15} ${crfStyle.margin0} ${crfStyle.pad_t10} ${crfStyle.pad_b5} ${crfStyle.hed5}`}
								>
									CPE CHANGES (IF APPLICABLE)
								</p>
								<p className={crfStyle.font15}>Addition of CPE</p>
								<div className={crfStyle.domestictable}>
									<div class=' '>
										{' '}
										<table
											width='100%'
											border='0'
											cellspacing='0'
											cellpadding='0'
										>
											<tbody>
												<tr>
													<td>Sr. No.</td>
													<td>Type (Standard/Other)</td>
													<td>Make</td>
													<td>Model</td>
													<td>Version</td>
												</tr>
												<tr>
													<td>
														{hsdWancrfDataupdated[348617]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348618]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348619]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348620]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348621]?.value || '\u00A0'}
													</td>
												</tr>
												<tr>
													<td>
														{hsdWancrfDataupdated[348622]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348623]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348624]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348625]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348626]?.value || '\u00A0'}
													</td>
												</tr>
												<tr>
													<td>
														{hsdWancrfDataupdated[348627]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348628]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348629]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348630]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348631]?.value || '\u00A0'}
													</td>
												</tr>
											</tbody>
										</table>{' '}
									</div>
								</div>
								{/* <p
									className={`${crfStyle.textcenter} ${crfStyle.font15} ${crfStyle.margin0} ${crfStyle.pad_t10} ${crfStyle.pad_b5} ${crfStyle.hed5}`}
								>
									Deletion of CPE
								</p> */}
								<p className={crfStyle.font15}>Deletion of CPE</p>
								<div className={crfStyle.domestictable}>
									<div class=' '>
										<table
											width='100%'
											border='0'
											cellspacing='0'
											cellpadding='0'
										>
											<tbody>
												<tr>
													<td>Sr. No.</td>
													<td>Type (Standard/Other)</td>
													<td>Make</td>
													<td>Model</td>
													<td>Version</td>
												</tr>
												<tr>
													<td>
														{hsdWancrfDataupdated[348633]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348634]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348635]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348636]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348637]?.value || '\u00A0'}
													</td>
												</tr>
												<tr>
													<td>
														{hsdWancrfDataupdated[348638]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348639]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348640]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348641]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348642]?.value || '\u00A0'}
													</td>
												</tr>
												<tr>
													<td>
														{hsdWancrfDataupdated[348643]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348644]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348646]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348647]?.value || '\u00A0'}
													</td>
													<td>
														{hsdWancrfDataupdated[348648]?.value || '\u00A0'}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<div className={crfStyle.signateNew}>
									{firstCaps !== '' ? (
										<div style={{ padding: '0px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData !== null && imageData !== '' ? (
										<div style={{ padding: '0px' }} className='signate_img'>
											<img
												src={
													imageData !== null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					</page>

					<SdwanCrfTermsConditionsNew
						isCrf={true}
						firstCaps={firstCaps}
						Signcss={Signcss}
						secondBold={secondBold}
						imageData={imageData}
						imageType={imageType}
					/>
					<page size='A4'>
						<div className='section2'>
							<div className='font8'>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									COMMENTS
								</p>
								<div className='cmt_spl'>
									{' '}
									Comments/ Special Considerations:{' '}
									{hsdWancrfDataupdated.hasOwnProperty(348648)
										? hsdWancrfDataupdated[348648].value
										: ''}{' '}
								</div>
								<div className='over_hid'>
									<div className='fleft width65 pad_t10'>
										<p className='margin0'>
											<strong>Declaration</strong>
										</p>
										<p className='margin0'>
											I / We confirm having received, read and understood the
											Product Terms & Conditions (provided overleaf) and the
											General Terms & Conditions. I / We further confirm that
											the tariff plan selected and applicable rates form part of
											this Agreement (as defined herein) and I / We agree to
											abide by the Applicable Law in force and also any
											statutory amendments, or new legistlations as may be
											enacted from time to time, in so far as they realte to the
											services. I / We hereby declare and confirm that the above
											information provided by us is true and correct in all
											respects and I / We hereby undertake to be bound by the
											same.{' '}
										</p>
										<p className='margin0 pad_5t'>
											Authorised Signatory's Name
										</p>
										<p className='margin0'>
											<input
												className='form_col width98per cmnt_input'
												type='text'
												name=''
												value={
													hsdWancrfDataupdated.hasOwnProperty(348655)
														? hsdWancrfDataupdated[348655].value
														: ''
												}
												readOnly
											/>
										</p>
										<div className='over_hid pad_t15'>
											<div className='fleft width30'>
												<p className='margin0'>Designation</p>
												<p className='margin0'>
													<input
														className='form_col widthfull cmnt_input'
														type='text'
														name=''
														value={
															hsdWancrfDataupdated.hasOwnProperty(348652)
																? hsdWancrfDataupdated[348652].value
																: ''
														}
														readOnly
													/>
												</p>
											</div>
											<div className='fleft width30 mar_l20'>
												<p className='margin0'>Place</p>
												<p className='margin0'>
													<input
														className='form_col widthfull cmnt_input'
														type='text'
														name=''
														value={
															hsdWancrfDataupdated.hasOwnProperty(348653)
																? hsdWancrfDataupdated[348653].value
																: ''
														}
														readOnly
													/>
												</p>
											</div>

											<div className='fleft width30 mar_l20'>
												<p className='margin0'>Date</p>
												<p className='margin0'>
													<input
														className='form_col width96per cmnt_input'
														type='text'
														name=''
														value={
															hsdWancrfDataupdated.hasOwnProperty(348654)
																? hsdWancrfDataupdated[348654].value
																: ''
														}
														readOnly
													/>
												</p>
											</div>
										</div>
										<p className='margin0 pad_5t'>Account Manager Name</p>
										<p className='margin0'>
											<input
												className='form_col width98per cmnt_input'
												type='text'
												name=''
												value={
													hsdWancrfDataupdated.hasOwnProperty(348656)
														? hsdWancrfDataupdated[348656].value
														: ''
												}
												readOnly
											/>
										</p>
										<p className='margin0 pad_20t'>
											Account Manager Circle Office
										</p>
										<p className='margin0'>
											<input
												className='form_col  wid120p cmnt_input'
												type='text'
												name=''
												value={
													hsdWancrfDataupdated.hasOwnProperty(348657)
														? hsdWancrfDataupdated[348657].value
														: ''
												}
												readOnly
											/>
										</p>
										<div className='pad_20t'>
											<div className='fleft width50'>
												<p className='margin0'>Channel Partner Name</p>
												<p className='margin0'>
													<input
														className='form_col widthfull cmnt_input'
														type='text'
														name=''
														value={
															hsdWancrfDataupdated.hasOwnProperty(348658)
																? hsdWancrfDataupdated[348658].value
																: ''
														}
														readOnly
													/>
												</p>
											</div>
											<div className='flright width30'>
												<p className='margin0'>Channel Partner Code</p>
												<p className='margin0'>
													<input
														className='form_col width92per cmnt_input'
														type='text'
														name=''
														value={
															hsdWancrfDataupdated.hasOwnProperty(348659)
																? hsdWancrfDataupdated[348659].value
																: ''
														}
														readOnly
													/>
												</p>
											</div>
										</div>
									</div>
									<div className='flright width30'>
										<div
											style={{
												padding: '5px',
												lineHeight: 'normal',
												width: '214px',
											}}
											className='signate'
										>
											{firstCaps !== '' ? (
												<div className={Signcss}>
													{firstCaps + '  .  '}
													<span className='under_line'>{secondBold}</span>
												</div>
											) : (
												''
											)}
											<div>
												Signature of the Customer / Authorised signatory with in
												this box only (In case of institutinal, please affix
												office/company seal){' '}
											</div>
										</div>
										<div
											style={{
												padding: '5px',
												height: '120px',
												marginTop: '140px',
												width: '216px',
												alignItems: 'center',
												display: 'flex',
											}}
											className='signate'
										>
											{acctMngSignImageData !== null &&
											acctMngSignImageData !== '' ? (
												<img
													height='90px'
													width='100px'
													src={
														acctMngSignImageData != null
															? 'data:' +
															  acctMngSignImageType +
															  ';base64,' +
															  encode(acctMngSignImageData)
															: ''
													}
													alt={'Signature of Account Manager'}
												/>
											) : (
												''
											)}
											<div>Signature of the Account Manager </div>
										</div>
										<div
											style={{
												padding: '5px',
												height: '120px',
												marginTop: '280px',
												width: '214px',
												alignItems: 'center',
											}}
											className='signate'
										>
											{imageData != null && imageData !== '' ? (
												<img
													height='90px'
													width='100px'
													src={
														imageData != null
															? 'data:' +
															  imageType +
															  ';base64,' +
															  encode(imageData)
															: ''
													}
													alt={'Company Seal'}
												/>
											) : (
												''
											)}
											<div>Company Seal</div>
										</div>
									</div>
								</div>
								<p className='fleft width65 pad_t10 textcenter'>
									<strong>
										Vodafone Idea Limited (formerly Idea Cellular Limited) An
										Aditya Birla Group and Vodafone Partnership Merger Co CIN
										No. L32100GJ1996PLC030976Registered Office: Suman Tower,
										Plot No. 18 Sector 11, Gandhinagar-382011, Gujarat.
									</strong>
								</p>
							</div>
						</div>
					</page>
				</div>
			</div>
		)
	} else {
		hSdwanCrfContentView = crfData ? (
			<div>
				<Center p={2} my={1}>
					<Spinner
						thickness='4px'
						speed='0.65s'
						emptyColor={chakraTheme.colors.secondary}
						color={chakraTheme.colors.brand[800]}
						size='md'
					/>
				</Center>
			</div>
		) : (
			<div style={{ marginTop: '200px', height: '300px', textAlign: 'center' }}>
				{' '}
				CAF Form not submitted yet.
			</div>
		)
	}
	return <div>{hSdwanCrfContentView}</div>
}

export default HsdwanCrfForm
